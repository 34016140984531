import React from 'react';

const Spinner = () => {
    const spinnerStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',  // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        zIndex: 999  // Ensure it's above other elements
    };

    const spinnerCircleStyle = {
        width: '60px',
        height: '60px',
        border: '6px solid #ddd',
        borderTopColor: '#007BFF',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
    };

    const keyframes = `
        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
    `;

    return (
        <>
            <style>{keyframes}</style>
            <div style={spinnerStyle}>
                <div style={spinnerCircleStyle}></div>
            </div>
        </>
    );
};

export default Spinner;
