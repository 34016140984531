import { useEffect } from "react";
const PopulateForms = ({ data }) => {
  useEffect(() => {
    if (data) {
      console.log("Received data in PopulateForms:", data);
      const fieldMapping = { ...window.campos };
      const eventMapping = window.eventos;
      const tipos = window.tipos;
      const ordenMapping = window.orden;

      console.log("Initial fieldMapping:", fieldMapping);
      console.log("eventMappiong", eventMapping);
      console.log("ordenMapping", ordenMapping);
      console.log("Tipos: ", tipos);

      // Dynamically update `nombre_propietario` based on `tipo_rut`
      if (data.tipo_rut === "PJ") {
        fieldMapping["nombre_propietario"] = "razon_social_propietario";
      } else {
        fieldMapping["nombre_propietario"] = "nombres_propietario";
      }
      console.log("Updated fieldMapping:", fieldMapping);

      let shouldFillData = false;

      if (tipos && tipos.length > 0) {
        if (tipos.includes(data.tipo_vehiculo)) {
          shouldFillData = true;
        } else {
          window.errorMsgFunction(
            `El documento es de un vehiculo que no se encuentra en la categoria de ${tipos[0]}`
          );
        }
      } else {
        shouldFillData = true;
      }

      if (shouldFillData) {
        Object.entries(fieldMapping).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).forEach(([key, id]) => {
          if (
            data[key] !== undefined &&
            key !== "ano_vehiculo" &&
            key !== "marca_vehiculo" &&
            key !== "modelo_vehiculo" &&
            key !== "tipo_veh"
          ) {

            console.log("Populating key: ", key, data[key])
            let element = document.getElementById(id);
            if (element) {
              element.value = data[key];
              // Check if an event is associated with this key
              const eventName = eventMapping[key];
              if (eventName) {
                // Dispatch the associated event
                const event = new CustomEvent(eventName, { bubbles: true,  detail: { origin: "react modal" }  }, );
                element.dispatchEvent(event);
                // console.log(`Triggered ${eventName} event for ${id}`);
              }
            }
          }
        });
        // 🔄 Convert ano, marca, and modelo from <select> to <input type="text" readonly>
        ["ano_vehiculo", "marca_vehiculo", "modelo_vehiculo"].forEach(
          (field) => {
            const elementID = fieldMapping[field]; // Get corresponding element ID
            const element = document.getElementById(elementID);

            if (element && element.tagName === "SELECT") {
              // console.log(`Converting ${field} from <select> to <input>`);

              // 🔄 Change the select into a readonly input instead of creating a new element
              element.outerHTML = `<input 
                type="text" 
                id="${elementID}" 
                readonly
                value="${data[field] || ""}"
                name="${elementID}" 
              />`;
            }
          }
        );

        // Set encriptado_modelo to 0
        const encriptadoModeloElement = document.getElementById("encriptado");
        if (encriptadoModeloElement) {
          encriptadoModeloElement.value = "0";
        }

        // Set encriptado_motor to 0
        const encriptadoMotorElement =
          document.getElementById("encriptado_motor");
        if (encriptadoMotorElement) {
          encriptadoMotorElement.value = "0";
        }

        const populateSequentially = async () => {
          // If it's empty, the function exits early.
          if (!ordenMapping || Object.keys(ordenMapping).length === 0) {
            return;
          }

          // Convert ordenMapping to an array of objects and sort by order value
          const orderedFields = Object.entries(ordenMapping)
            .map(([field, order]) => ({ field, order: parseInt(order, 10) })) // Converts numbers into int
            .sort((a, b) => a.order - b.order); // Sort in ascending order
          console.log("OrderedFields: ", orderedFields);
          console.log(
            "🚀 Ordered fields for population:",
            orderedFields.map((f) => f.field)
          );

          for (const { field } of orderedFields) {
            if (field !== "tipo_veh") {
              // 🚨 Ignore `tipo_veh` in population
              const elementID = fieldMapping[field];
              const element = document.getElementById(elementID);
              // console.log("Element: ", element);

              if (element && data[field]) {
                console.log(
                  `⏳ Populating field: ${field} (ID: ${elementID}) with value:`,
                  data[field]
                );
                element.value = data[field];
                // console.log("element.value: ", element.value);
                element.dispatchEvent(new Event("change", { bubbles: true }));
                console.log(
                  `✅ Successfully populated ${field} with value:`,
                  element.value
                );

                // ⏳ Wait before filling next field (fixes dropdown issues)
                await new Promise((resolve) => setTimeout(resolve, 1500));
              }
            }
          }
        };
        populateSequentially();

        // Add labels for modelo and motor only if they don't exist
        const addLabelIfNeeded = (elementId, labelText) => {
          const element = document.getElementById(elementId);

          if (element) {
            // Check if there's a label directly before the element in the DOM
            const existingLabel = element.previousElementSibling;
            const isLabelForElement =
              existingLabel?.tagName === "LABEL" &&
              existingLabel.getAttribute("for") === elementId;

            if (!isLabelForElement) {
              // Create and insert the label if it doesn't exist
              const label = document.createElement("label");
              label.setAttribute("for", elementId);
              label.textContent = labelText;
              element.parentNode.insertBefore(label, element);

              // Special case for "motor" element to add extra text
              if (elementId === "motor") {
                const additionalText = document.createElement("p");
                additionalText.textContent =
                  "(vehículos sin motor: número chasis, o serie o VIN)";
                element.parentNode.insertBefore(
                  additionalText,
                  label.nextSibling
                );
              }
            }
          }
        };

        // General function to process elements
        const processElement = (elementId, labelText) => {
          const element = document.getElementById(elementId);
          if (element) {
            if (element.tagName === "SELECT") {
              // console.log(
              //   `${elementId} is a select element. Type cannot be changed.`
              // );
            } else {
              // If type is "hidden", change it to "text" and add label
              if (element.type === "hidden") {
                element.type = "text";
                addLabelIfNeeded(elementId, labelText);
                // console.log(`${elementId} type changed from hidden to text`);
              }
            }
          }
        };

        // Process "marca", "modelo", and "motor" elements
        processElement("marca", "Marca (*)");
        processElement("modelo", "Modelo (*)");

        // For "motor", include additional text after adding the label
        processElement("motor", "Número de motor (*)");
      }
    }
  }, [data]);

  return null; // This component does not render anything visually
};

export default PopulateForms;
