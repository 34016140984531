import React, { useState, useEffect, useMemo } from "react";
import PropTypes, { object } from "prop-types";
import Spinner from "./Spinner";
import "../styles/estilo.css";

const EditableForm = ({ formData, setFormData, documento, loading, formDataFieldErrors, setFormDataFieldErrors }) => {
  // Get campos_por_documentos from global window object
  const camposPorDocumentos = window.campos_por_documentos;
  const isInternalUser = window.is_internal_user;

  console.log("Is internal user: ", isInternalUser);

  // Ensure documento exists in camposPorDocumentos
  const selectedCampos = camposPorDocumentos?.[documento]?.campos || {};
  const campos = Object.entries(selectedCampos);

  // Group fields into chunks of 7
  const fieldGroups = [];
  for (let i = 0; i < campos.length; i += 7) {
    fieldGroups.push(campos.slice(i, i + 7));
  }

  const motorReadOnly = useMemo(() => {
    // This function is only run on the first render.
    return formData['num_motor_vehiculo'] !== "" && formData['num_motor_vehiculo'] !== undefined
  }, []); // Empty dependency array ensures it's only calculated once.

  console.log("Motor read only", motorReadOnly)


  // ✅ State for tracking which fields are being edited
  const [editableFields, setEditableFields] = useState({});
  const [localFormData, setLocalFormData] = useState(formData);

  const [onChangeDone, setOnChangeDone] = useState(true); // initially true

  const [initiallyEmptyFields, setInitiallyEmptyFields] = useState({});

  const [forceSelect, setForceSelect] = useState(() => {
    return Object.keys(formData).reduce((acc, key) => {
        const values = selectedCampos[key]?.values;


        //filtra tipos de uso por tipo de veh
        if(key === "tipo_vehiculo")  {
          acc[key] = true

          if(formData[key] !== "" && values.some(obj => obj.type === formData[key])) {
            
            let filteredTypes = values.filter(obj => obj.type === formData[key])
            selectedCampos[key].values.splice(0,values.length, ...filteredTypes )
            console.log("Filtering use types")
            if(filteredTypes.length === 1) {
              setLocalFormData((prev) => ({
                ...prev,
                "tipo_uso": filteredTypes[0].value
              }));
              setFormData((prev) => ({
                ...prev,
                "tipo_uso": filteredTypes[0].value
              }));
            }
          }
          return acc
        }

        if (!values) {
            acc[key] = false;
        } else if (Array.isArray(values) && values.length > 0) {
            if (typeof values[0] === "string") {
                // Case 1: values is an array of strings
                acc[key] = !values.includes(formData[key]);
            } else if (typeof values[0] === "object" && values[0].type) {
                // Case 2: values is an array of objects with a 'type' field
                acc[key] = !values.some(obj => obj.type === formData[key]);
            } else {
                // Fallback if structure is unexpected
                acc[key] = false;
            }
        } else if (formData[key] === "") {
            acc[key] = true
        }else {
            acc[key] = false;
        }

        return acc;
    }, {});
  });


  // Initialize when formData is first loaded
  // ✅ Modify editable fields locally if they start empty
  useEffect(() => {
    const updateEditableFields = () => {
      const emptyFields = Object.keys(formData).reduce((acc, key) => {
        const fieldValue = formData[key] || ""; // Ensure empty values are recognized
        const fieldConfig = selectedCampos[key] || {}; // Get field info

        // ✅ If field is "N" and empty, make it "S" locally
        //console.log("Key is ", key)
        //console.log("campos", selectedCampos)
        if ((fieldConfig.editable === "N" || fieldConfig.editable === "U") && (fieldValue === "" || (selectedCampos[key].values && !selectedCampos[key].values.includes(fieldValue)))  ) {
          selectedCampos[key] = { ...fieldConfig, editable: "S" };
        }

        acc[key] = fieldValue === ""; // Track if initially empty
        return acc;
      }, {});

      setInitiallyEmptyFields(emptyFields);
    };

    updateEditableFields(); // ✅ Call function inside useEffect
  }, [formData]); // ✅ Only depends on formData

  // Ensure localFormData updates when formData changes
  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  //Update field options for the fields that depend on external sources
  useEffect(() => {
    if(!onChangeDone) return
    Object.keys(selectedCampos).forEach((key) => {
      const field = selectedCampos[key];
      console.log("checking", key, field)
      if (field.key_to_set && selectedCampos[field.key_to_set]?.values?.length === 0) {
        const dependsOn = field.dependsOn || [];
        const isValid = dependsOn.every(
          (depKey) => {
              console.log("dep checking", depKey, localFormData[depKey], selectedCampos[depKey].values)
              const values = selectedCampos[depKey]?.values;
              let valid = false
              if (!values) {
                  valid = false;
              } else if (Array.isArray(values) && values.length > 0) {
                  if (typeof values[0] === "string") {
                      // Case 1: values is an array of strings
                      valid = values.includes(localFormData[depKey]);
                  } else if (typeof values[0] === "object" && values[0].type) {
                      // Case 2: values is an array of objects with a 'type' field
                      valid = values.some(obj => obj.type === localFormData[depKey]);
                  } else {
                      // Fallback if structure is unexpected
                      valid = false;
                  }
              } else {
                  valid = false;
              }
              console.log("Valid:", valid )
              return localFormData[depKey] !== "" && valid
          }
        );
        console.log("IS valid?", isValid, field)
        if (isValid && field.onChange) {
          console.log("Executing onchange for", key)
          field.onChange().then(result => {
            selectedCampos[field.key_to_set].values = JSON.parse(result)
            if(JSON.parse(result).length >0) {
              console.log("Updating force select", JSON.parse(result))
              if(JSON.parse(result).length === 1 ) {
                if(field.key_to_set === "tipo_vehiculo") {
      
                  setLocalFormData((prev) => ({
                    ...prev,
                    [field.key_to_set]: JSON.parse(result)[0].type,
                    "tipo_uso": JSON.parse(result).value
                  }));
                  setFormData((prev) => ({
                    ...prev,
                    [field.key_to_set]: JSON.parse(result)[0].type,
                    "tipo_uso": JSON.parse(result).value
                  }));
            
                } else {
            
                  setLocalFormData((prev) => ({
                    ...prev,
                    [field.key_to_set]: JSON.parse(result)[0],
                  }));
                  setFormData((prev) => ({
                    ...prev,
                    [field.key_to_set]: JSON.parse(result)[0], // Use 'value' directly instead of localFormData[fieldName]
                  }));
            
                }
              }
              setEditableFields({})
              /*
              setForceSelect((prev) => ({  
                ...prev,
                [field.key_to_set]: true
              }));*/
            }
        }).catch(error => console.error("Error:", error));
        }
      }
    });

  }, [localFormData, selectedCampos,onChangeDone]);


  /*
  useEffect(() => {

    console.log("editabel", editableFields)
    if(Object.entries(editableFields).length >= 0) {
      isEditing = true
    } else {
      isEditing = false
    }
  }, );*/

  // Responsive State
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  // Handle screen resize to toggle flex-wrap
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Debugging logs
  console.log("formData: ", formData);
  // console.log("Documento: ", documento);
  console.log("Campos por documentos: ", camposPorDocumentos);

  /**
   * Toggles edit mode for a specific field.
   */
  const toggleEdit = (fieldName) => {
    console.log(editableFields)
    console.log("Edit")
    setEditableFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  /**
   * Handles field value changes
   */
  const handleInputChange = async (fieldName, { value, label, type }) => {
    console.log("Selected:", { fieldName, value, label, type });

    console.log(localFormData);
    
    if(fieldName === "tipo_vehiculo") {
      
      setLocalFormData((prev) => ({
        ...prev,
        [fieldName]: type,
        "tipo_uso": value
      }));
      setFormData((prev) => ({
        ...prev,
        [fieldName]: type, // Use 'value' directly instead of localFormData[fieldName]
        "tipo_uso": value
      }));

    } else {

      setLocalFormData((prev) => ({
        ...prev,
        [fieldName]: value,
      }));
      setFormData((prev) => ({
        ...prev,
        [fieldName]: value, // Use 'value' directly instead of localFormData[fieldName]
      }));

    }

    setFormDataFieldErrors((prev) => ({
      ...prev,
      [fieldName]: false,
    }));

    setOnChangeDone(false);

    if (selectedCampos[fieldName].onChange) {
      console.log("Calling onChange in event handler");
      try {
        const result = await selectedCampos[fieldName].onChange();
        console.log("Return", result);
        selectedCampos[selectedCampos[fieldName].key_to_set].values = JSON.parse(result);
        
        if(localFormData[selectedCampos[fieldName].key_to_set] === "") {

          console.log("Updating forceSelect:", selectedCampos[fieldName].key_to_set);
          setForceSelect(prev => ({
            ...prev,
            [selectedCampos[fieldName].key_to_set]: true,
          }));
          let res = JSON.parse(result)
          if(res.length === 1) {
            setLocalFormData((prev) => ({
              ...prev,
              [selectedCampos[fieldName].key_to_set]: res[0],
            }));
            setFormData((prev) => ({
              ...prev,
              [selectedCampos[fieldName].key_to_set]: res[0],
            }));
          }

        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  

  /**
   * Saves the updated form data to FileUploaderPopup
   */
  // ✅ Save updated data to `FileUploaderPopup`
  const handleSave = (fieldName) => {
    console.log(editableFields)
    console.log(initiallyEmptyFields)
    console.log("Save")
    console.log(fieldName)
    console.log(localFormData[fieldName])
    setFormData((prev) => ({
      ...prev,
      [fieldName]: localFormData[fieldName],
    }));
    setEditableFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));

    // If field was initially empty, keep the button visible
    /*if (!initiallyEmptyFields[fieldName]) {
      toggleEdit(fieldName);
    }*/
  };

  console.log("Force Select ", forceSelect)

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center",
        marginBottom: "15px",
      }}
    > 
      <div>
        <h2>Confirma los Datos</h2>
        <br></br>
        <p>Revisa los datos, confirma sólo si están correctos. <br></br>
        Si hay algún dato incompleto, complétalo.</p>
        <a href="https://www.soapok.cl/seguros-online/sopo1">Necesitas ayuda?</a>
      </div>
      {fieldGroups.length === 0 ? (
        <p>No se encontraron campos para este documento.</p>
      ) : (
        <form
          className="clearfix"
          onSubmit={(e) => e.preventDefault()}
          style={{
            display: "flex",
            justifyContent: "center", // Ensures 2-column layout
            width: "100%",
            flexWrap: isSmallScreen ? "wrap" : "nowrap",
          }}
        >
          {fieldGroups.map((fields, fieldsetIndex) => (
            <fieldset
              key={fieldsetIndex}
              style={{
                flex: "1 1 48%", // Each fieldset takes ~50% width
                minWidth: "300px",
                maxWidth: "48%",
                padding: "10px 20px 20px 20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Dynamic Fields */}
              {fields.map(([fieldName, fieldData], index) => (
                <div
                  key={fieldName}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  {/* Label on top */}
                  <label
                    style={{
                      textAlign: "left",
                      padding: "5px 0",
                      fontSize: "13px",
                    }}
                  >
                    {fieldData.label} (*)
                  </label>

                  {/* Input Field with Edit Button */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                  {forceSelect[fieldName]|| (formData[fieldName] === "" && fieldData.values !== undefined)   ? (
                  // Render a select dropdown if "values" exists
                  <select
                  value={
                    Array.isArray(fieldData.values) && fieldData.values.length === 1
                      ? typeof fieldData.values[0] === "object"
                        ? fieldData.values[0].value  // Auto-selects the only option if present
                        : fieldData.values[0]
                      : Array.isArray(fieldData.values) && fieldData.values.some(option =>
                          typeof option === "object"
                            ? option.value === localFormData[fieldName === "tipo_vehiculo" ? "tipo_uso" : fieldName]
                            : option === localFormData[fieldName === "tipo_vehiculo" ? "tipo_uso" : fieldName]
                        )
                      ? localFormData[fieldName === "tipo_vehiculo" ? "tipo_uso" : fieldName]
                      : ""
                  }
                    onChange={(e) => {
                      const selectedOption = Array.isArray(fieldData.values)
                        ? fieldData.values.find(option =>
                            typeof option === "object" ? option.value === e.target.value : option === e.target.value
                          )
                        : null;

                      handleInputChange(fieldName, 
                        typeof selectedOption === "object"
                          ? {
                              value: selectedOption?.value || "",
                              label: selectedOption?.label || "",
                              type: selectedOption?.type || "default",
                            }
                          : { value: selectedOption || "", label: selectedOption || "", type: "default" }
                      );
                    }}
                    style={{
                      flexGrow: 1,
                      padding: "8px",
                      borderRadius: "5px",
                      border: formDataFieldErrors[fieldName] ?  "1px solid #e84a36" :  "1px solid #ccc",
                      backgroundColor: "#fff",
                    }}
                    id={fieldName+"_input"}
                  >
                    {Array.isArray(fieldData.values) && fieldData.values.length > 1 && (
                      <option style={{ textTransform: "uppercase" }} value="" disabled>
                        Seleccione...
                      </option>
                    )}

                    {Array.isArray(fieldData.values) && typeof fieldData.values[0] === "object" ? (
                      (() => {
                        let lastCampana = null;
                        return fieldData.values.map((option, index) => {
                          const isNewCampana = option.campana !== lastCampana;
                          lastCampana = option.campana;

                          return (
                            <React.Fragment key={index}>
                              {/* Group header (only appears when campana changes) */}
                              {isNewCampana && (
                                <option value="" disabled style={{ fontWeight: "bold" }}>
                                  --- {option.campana === "MAR"
                                    ? "Vehículos Particulares"
                                    : option.campana === "MAY"
                                    ? "Transporte Pasajeros"
                                    : option.campana === "SEP"
                                    ? "Transporte Carga"
                                    : `Grupo ${option.campana}`}:
                                </option>
                              )}
                              {/* Actual selectable option */}
                              <option value={option.value} data-type={option.type}>
                                {option.label}
                              </option>
                            </React.Fragment>
                          );
                        });
                      })()
                    ) : (
                      // If the values are simple strings, render a normal select without groups
                      fieldData.values.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))
                    )}
                </select>

                ) : (
                  // Render a text input otherwise
                  <input
                    type="text"
                    value={localFormData[fieldName] || ""}
                    onChange={(e) => handleInputChange(fieldName,  {
                      value: e.target.value,
                      label: undefined,
                      type: undefined,
                    })}
                    style={{
                      flexGrow: 1,
                      padding: "8px",
                      borderRadius: "5px",
                      border: formDataFieldErrors[fieldName] ?  "1px solid #e84a36" :  "1px solid #ccc",
                      backgroundColor: !motorReadOnly ? "#fff" : "#f3f3f3",
                    }}
                    //readOnly={localFormData[fieldName] !== "" && localFormData[fieldName] !== undefined}
                    readOnly={motorReadOnly}
                    id={fieldName+"_input"}
                    data-type={localFormData[fieldName]}
                  />
                )}
                  </div>
                </div>
              ))}
            </fieldset>
          ))}
        </form>
      )}
      {loading && <Spinner />}
    </div>
  );
};

// PropTypes validation
EditableForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  documento: PropTypes.number.isRequired,
};

export default EditableForm;
