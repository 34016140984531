/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import "../styles/estilo.css";
import PopulateForms from "./PopulateForms";
import Spinner from "./Spinner";
import EditableForm from "./EditableForm";
// 2025-01-22 reyes:
const FileUploaderPopup = ({ onClose, onSubmit }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formDataFieldErrors, setFormDataFieldErrors] = useState({});
  const [editableFormData, setEditableFormData] = useState(null);
  const [listData, setListData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [showDocumentDropdown, setShowDocumentDropdow] = useState(true);
  const [readId, setReadId] = useState(null);
  const listRef = useRef(null);
  const dropAreaRef = useRef(null);
  const originFile = window.flujoName;
  let timeoutId = null;
  const [showPopulateForms, setShowPopulateForms] = useState(false);
  const [idMatAseg, setIdMatAseg] = useState(null);
  const popupRef = useRef(null);
  const [error, setError] = useState(false); // Add error state
  const MAX_FILES = 1;
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/jpg",
  ];
  const maxFileSize = 5000 * 1024; // 5000 KB in bytes
  const timeout = window.timeout;
  const POPUP_TIMEOUT = timeout * 1000;
  const [showErrors, setShowErrors] = useState(false);

  const confirmInModal = window.confirmInModal;
  const tipos_documentos = window.tipos_documentos;

  // 2025-01-22 reyes: Funcion para formatear/limpiar string del patente
  const sanitizePatente = (patente) => {
    if (!patente) {
      console.error("Patente invalida: ", patente);
      return null;
    }
    // 2025-01-22 reyes: Sacar puntos del patente
    let sanitizePatente = patente.replace(/\./g, "");

    // 2025-01-22 reyes: Quitar digito verificador del patente
    sanitizePatente = sanitizePatente.split("-")[0];

    return sanitizePatente;
  };

  // 2025-01-22 reyes: Funcion para validar tamaño/formato del archivo
  const validateFile = (file) => {
    // 2025-01-22 reyes: Verificar tamaño permitido del archivo
    if (file.size > maxFileSize) {
      window.errorMsgFunction(
        `El archivo "${file.name}" sobrepasa el limite de tamaño "5MB"`
      );
      return false;
    }

    // 2025-01-22 reyes: Verificar formatos permitidos del archivo
    if (!allowedFileTypes.includes(file.type)) {
      window.errorMsgFunction(
        `El archivo "${file.name}" se encuentra en un formato invalido. Por favor utiliza: JPEG, JPG, PNG, PDF.`
      );
      return false;
    }

    return true;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    const validFiles = droppedFiles.filter(validateFile);

    if (files.length + validFiles.length > MAX_FILES) {
      window.errorMsgFunction(`Solo puedes subir hasta ${MAX_FILES} archivos.`);
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(validateFile);

    if (files.length + validFiles.length > MAX_FILES) {
      window.errorMsgFunction(`Solo puedes subir hasta ${MAX_FILES} archivos.`);
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleButtonClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileRemove = (index) => {
    setShowDocumentDropdow(true);
    if (listData) {
      setListData(null);
    }
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

    document.getElementById("file-input").value = "";
  };

  //   const handleFileChange = (event) => {
  //     const selectedFiles = Array.from(event.target.files);

  //     const validFiles = selectedFiles.filter((file) => validateFile(file));

  //     if (validFiles.length === 0) {
  //       alert("No se han seleccionado archivos validos. Intente de nuevo");
  //       return;
  //     }

  //     setFiles(validFiles);
  //   };

  const handleSubmit = async () => {

    // check if a document is selected
    if (!selectedDocument) {
      window.errorMsgFunction(
        "Debes seleccionar un tipo de documento antes de continuar"
      );
      setError(true)
      return
    }

    // check if a file is selected
    if (files.length === 0) {
      window.errorMsgFunction(
        "Por favor sube al menos un archivo para continuar."
      );
      return;
    }

    setError(false)

    setLoading(true); // 2025-01-22 reyes: Mostrar spinner

    // 2025-01-22 reyes: Establece un tiempo de espera para cancelar las llamadas de API en curso si exceden el límite de tiempo definido (POPUP_TIMEOUT).
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => {
      window.errorMsgFunction("Hubo un error. Porfavor intenta denuevo.");
    }, POPUP_TIMEOUT);

    const executeSubmit = async () => {
      // 2025-01-22 reyes: Funcion para convertir un archivo en formato Base64.
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const fetchData = async (firstApiResult) => {
        try {
          const myHeaders = new Headers();
          myHeaders.append("content-type", "application/json");
          const url = new URL(
            "https://85sp2ci7b1.execute-api.us-east-1.amazonaws.com/Prod/documents/getData"
          );
          url.searchParams.append("token", "a");
          url.searchParams.append("read_id", firstApiResult.data.read_id);
          const requestOptions = {
            method: "GET",
          };

          // 2025-01-22 reyes: Llamado de Segunda API
          const secondApiResponse = await fetch(url, requestOptions);
          // 2025-01-22 reyes: Llamado de Segunda API
          // const secondApiResponse = await fetch(
          //   `https://85sp2ci7b1.execute-api.us-east-1.amazonaws.com/Prod/documents/getData?token=${"a"}&read_id=${"12"}`,
          //   {
          //     method: "GET",
          //     headers: { "Content-Type": "application/json" },
          //   }
          // );

          // 2025-01-22 reyes: Verifica si el estado de la respuesta es "OK"
          if (!secondApiResponse.ok) {
            window.errorMsgFunction("Hubo un error. Porfavor intenta denuevo.");
            throw new Error(
              `First API failed with status ${secondApiResponse.status}`
            );
          }

          // 2025-01-22 reyes: Procesa la respuesta JSON de la API
          const secondApiResult = await secondApiResponse.json();

          setReadId(firstApiResult.data.read_id);

          return secondApiResult;
        } catch (error) {
          // 2025-01-22 reyes: Muestra un mensaje si la API devolvió un estado de error
          window.errorMsgFunction("Hubo un error. Porfavor intenta denuevo.");
          throw error;
        }
      };

      const processDocumentData = (fetchData) => {
        let tipoRut = "";

        if(fetchData.rut) {
          const rut = fetchData.rut_propietario.replace(/\./g, "").split("-")[0];
          if (Number(rut) > 49000000) {
            tipoRut = "PJ";
            fetchData.razon_social = fetchData.nombre_propietario;
          } else {
            tipoRut = "PN";
            let namesPart = fetchData.nombre_propietario.trim().split(/\s+/);
            let firstName = "";
            let middleName = "";
            let firstLastName = "";
            let secondLastName = "";
            if (namesPart.length === 2) {
              [firstName, firstLastName] = namesPart;
            } else if (namesPart.length === 3) {
              [firstName, firstLastName, secondLastName] = namesPart;
            } else if (namesPart.length === 4) {
              [firstName, middleName, firstLastName, secondLastName] = namesPart;
            }
            fetchData.nombres_propietario = firstName + " " + middleName;
            fetchData.apellido_paterno_propietario = firstLastName;
            fetchData.apellido_materno_propietario = secondLastName;
          }
          fetchData.tipo_rut = tipoRut;
          window.tipoRut = fetchData.tipo_rut;
          window.razon_social = fetchData.razon_social;
          window.nombres_prop = fetchData.nombres_propietario;
          window.apellido_paterno_propietario =
            fetchData.apellido_paterno_propietario;
          window.apellido_materno_propietario =
            fetchData.apellido_materno_propietario;
        }
        const parts = fetchData.patente.replace(/\./g, "").split("-");
        fetchData.ppu = parts[0] || "";
        fetchData.ppu_dv = parts[1] || "";
        console.log(fetchData);

        const uppercasedData = Object.fromEntries(
          Object.entries(fetchData).map(([key, value]) => [key, typeof value === "string" ? value.toUpperCase() : value])
        );
      
        return uppercasedData;
      };

      try {
        // 2025-01-22 reyes: Convertir archivos a Base64
        const base64Files = await Promise.all(
          files.map(async (file) => ({
            "x-filename": file.name,
            base64: await convertToBase64(file),
          }))
        );

        // 2025-01-22 reyes: Itera sobre los archivos convertidos para procesarlos uno por uno
        for (const { "x-filename": filename, base64 } of base64Files) {
          // Convert filename to ASCII-safe string before using it
          const safeFilename = encodeURIComponent(filename);

          // 2025-01-22 reyes: Llamado de primera API
          const firstApiResponse = await fetch(
            "https://vw2f2cilta.execute-api.us-east-1.amazonaws.com/DocumentExtract",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-filename": safeFilename,
                "x-document-type": selectedDocument,
                "x-origin-file": originFile,
              },
              body: JSON.stringify({ file: base64 }),
              signal: abortController.signal,
            }
          );

          // 2025-01-22 reyes: Verifica si la respuesta de la API no es exitosa
          if (!firstApiResponse.ok) {
            window.errorMsgFunction(
              "No se pudo cargar el archivo. Porfavor intenta denuevo."
            );
            throw new Error("No se pudo cargar el archivo");
          }

          // 2025-01-22 reyes: Procesa la respuesta JSON de la API
          const firstApiResult = await firstApiResponse.json();
          console.log("Api1: ", firstApiResult);

          // 2025-01-22 reyes: Verifica si el estado de la respuesta es "OK"
          if (firstApiResult.status === "OK") {
            // hide document dropdown
            //setShowDocumentDropdow(false);

            // 2025-01-22 reyes: Llama a fetchData para obtener más datos relacionados con el archivo procesado
            const fetchDataResult = await fetchData(firstApiResult);
            const processedFetchDataResult =
              processDocumentData(fetchDataResult); 
              // Create a new object with the same keys but val set to false
              const formDataFieldErrors = Object.keys(processedFetchDataResult).reduce((acc, key) => 
              {
                acc[key] = false
                return acc;
              }, {});
            console.log(processedFetchDataResult);
            console.log("Fields with errors:", formDataFieldErrors)
            if (confirmInModal) {
              setListData(processedFetchDataResult);
              setEditableFormData(processedFetchDataResult);
              setFormDataFieldErrors(formDataFieldErrors)
            } else {
              setFormData(processedFetchDataResult); // Actualiza `formData`
              // setListData(processedFetchDataResult);
              // setEditableFormData(processedFetchDataResult);
            }
            console.log("FetchDataResult: ", processedFetchDataResult);
            clearTimeout(timeoutId);
          } else {
            // 2025-01-22 reyes: Muestra un mensaje si la API devolvió un estado de error
            console.error(firstApiResult.message);
            window.errorMsgFunction(
              `${firstApiResult.message}`
            );
            clearTimeout(timeoutId);
          }
        }
      } catch (error) {
        // 2025-01-22 reyes: Maneja errores específicos relacionados con el tiempo de espera de la solicitud
        if (error.name === "AbortError") {
          console.error("Llamada API cancelada debido al tiempo de espera");
          window.errorMsgFunction("Hubo un error. Porfavor intenta denuevo.");
          clearTimeout(timeoutId);
        } else {
          // 2025-01-22 reyes: Maneja otros errores, como problemas de red o errores del servidor
          console.error("Error submitting files:", error);
          window.errorMsgFunction("Hubo un error. Porfavor intenta denuevo.");
          clearTimeout(timeoutId);
        }
      }
    };

    await executeSubmit();
    setLoading(false); // 2025-01-22 reyes: Quita el spinner
  };

  // const handleConfirm = () => {
  //   setLoading(true);
  //   if (listData) {
  //     setFormData(listData);
  //   }
  // };
  const handleConfirm = async () => {

     // Get campos_por_documentos from global window object
    const camposPorDocumentos = window.campos_por_documentos;

    // Ensure documento exists in camposPorDocumentos
    const selectedCampos = camposPorDocumentos?.[selectedDocument]?.campos || {};



    if (!readId) {
      console.error("No se encontró el identificador del documento");
      return;
    }

    setLoading(true); // Ensure this executes first

    // ✅ Generate payload including ALL fields, but track modifications
    const fields = Object.keys(listData).reduce((acc, key) => {

      console.log("Checking:", key, editableFormData[key])
      const values = selectedCampos[key]?.values;
      console.log("Values",values)

      let hasError = false
      if (!values || !selectedCampos[key].strong_validation) {
          hasError = false
      } else if (Array.isArray(values) && values.length > 0) {
          if (typeof values[0] === "string") {
              // Case 1: values is an array of strings
              hasError = !values.includes(editableFormData[key]);
          } else if (typeof values[0] === "object" && values[0].type) {
              // Case 2: values is an array of objects with a 'type' field
              hasError = !values.some(obj => obj.type === editableFormData[key]);
          } 
      }

      if(key === "tipo_vehiculo") {

        hasError = editableFormData['tipo_uso'] === undefined || editableFormData['tipo_uso'] === "" 

      }

      
      if (!editableFormData[key] || editableFormData[key] === "" || hasError) {
        console.error(`Field ${key} cannot be empty.`);
        setLoading(false)
        setFormDataFieldErrors(( prev) => ({
          ...prev,
          [key]: true,
        }));
        window.errorMsgFunction("Información faltante.")
        return;
      }
      const hasChanged = editableFormData[key] !== listData[key];

      acc[key] = {
        modified: hasChanged,
        new_value: hasChanged ? editableFormData[key] : "",
      };

      return acc;
    }, {});

      // ✅ Identify extra keys in editableFormData that are not in listData
    const extraData = Object.keys(editableFormData).reduce((acc, key) => {
      if (!(key in listData)) {
        acc[key] = editableFormData[key];
      }
      return acc;
    }, {});

    // ✅ Create the final payload
    const payload = {
      read_id: readId,
      fields,
      extraData, // Include extra data
    };

    console.log("Enviando confirmación con payload:", payload);

    try {
      const response = await fetch(
        "https://85sp2ci7b1.execute-api.us-east-1.amazonaws.com/Prod/documents/confirmData",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Error al confirmar los datos");
      }

      const result = await response.json();
      console.log("Confirmación exitosa:", result);
      setIdMatAseg(result.id_mat_aseg);
      

      // ✅ Ensure `id_mat_aseg` exists in editableFormData (empty by default)
      setEditableFormData((prevData) => ({
        ...prevData,
        id_mat_aseg: result.id_mat_aseg || "", // Assign if exists, otherwise empty string
      }));

      // ✅ Ensure latest formData is updated after a successful request
      setTimeout(() => {
        setFormData(editableFormData);
        console.log("Updated FormData", editableFormData);

        // Trigger rendering of PopulateForms only AFTER confirmation
        setShowPopulateForms(true);
      }, 0);
    } catch (error) {
      console.error("Error en la confirmación:", error);
      setLoading(false);
    }
  };

  const handleReturn = () => {
    if (listData) {
      setListData(null);
    }
    window.resetWindowVariables()
  };

  useEffect(() => {
    if (idMatAseg) {
      console.log("📢 Enviando idMatAseg a PHP:", idMatAseg);
      window.idMatAseg = idMatAseg;
    }
  }, [idMatAseg]);

  // 2025-01-22 reyes: Maneja el cierre del popup con un retraso una vez que se completa formData.
  useEffect(() => {
    if (formData) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(() => {
        onClose();
        setLoading(false);
        window.successMsgFunction("Registro exitoso");
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [formData, onClose]);

  // 2025-01-22 reyes: Evitar scroll cuando el popup esta abierto
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  // Function to render the dropdown options
  const renderDocumentOptions = () => {
    return tipos_documentos.map((option, index) => (
      <option
        key={index}
        value={option.value}
        style={{ textTransform: "uppercase" }}
      >
        {option.label}
      </option>
    ));
  };
  console.log("Selected Document: ", selectedDocument);

  const handleSelectChange = (e) => {
    setError(false)
    setSelectedDocument(e.target.value)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
        window.resetWindowVariables()
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <>
      <div
        className={`esNuevoModal modal-window focus 
        }`}
        // onDragOver={handleDragOver}
        // onDragLeave={handleDragLeave}
        // onDrop={handleDrop}
      >
        {!listData ? (
          <>
            <div ref={popupRef}>
              <h2>Ingresar Vehículo</h2>
              <br></br>
              {window.modalExtraMessage !== '' ? <p style={{color: "red"}}>{window.modalExtraMessage}</p> : null}
              <p>Sube un documento del vehículo.<br></br>Los datos se registrarán sin necesidad de digitarlos.</p>
              {/* Dropdown */}
              {showDocumentDropdown && (
                <div
                  style={{
                    marginTop: "20px",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      alignContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "13px",
                        color: "#3b748c",
                        alignSelf: "flex-start",
                      }}
                    >
                      Tipo de Documento (*)
                    </label>
                    <select
                      className="document-dropdown"
                      style={{
                        appearance: "none",
                        backgroundImage:
                          "url(https://desa-gh.seguropro.cl/seguros-online/img/fondo-select.jpg)",
                        backgroundPosition: "right",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#fff",
                        padding: "6px",
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        margin: "2px",
                        borderRadius: "5px",
                        fontSize: "13px",
                        color: "#595a5a",
                        width: "calc(70% + 40px)",
                        cursor: "pointer",
                        textTransform: "uppercase",
                        border: error ? "1px solid red" : "1px solid #ccc", // Apply red border on error
                      }}
                      value={selectedDocument}
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <option
                        style={{ textTransform: "uppercase" }}
                        value=""
                        disabled
                      >
                        Seleccione...
                      </option>
                      {renderDocumentOptions()}
                    </select>
                  </div>
                </div>
              )}
              {loading && <Spinner />}
              {files.length === 0 && (
                <div
                  className={`drag-drop-area ${dragging ? "dragging" : ""}`}
                  onClick={handleButtonClick}
                  style={{
                    border: dragging ? "2px dashed #007BFF" : "2px dashed #CCC",
                    textAlign: "center",
                    cursor: "pointer",
                    margin: "20px",
                    padding: "20px",
                    width: "70%",
                  }}
                  ref={dropAreaRef}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <p>Arrastra aquí</p>
                  <p>o</p>
                  <button
                    style={{
                      padding: "8px 50px 8px 30px",
                      backgroundColor: "#aaaaaa",
                    }}
                    type="button"
                    className="btn"
                  >
                    Buscar Archivos
                  </button>
                </div>
              )}
              <input
                id="file-input"
                type="file"
                style={{ display: "none" }}
                multiple
                onChange={handleFileSelect}
              />
              <div
                style={{
                  overflowY: "auto",
                  border: files.length > 0 ? "1px solid #CCC" : "none",
                  margin: "10px 0",
                  padding: files.length > 0 ? "5px" : "0",
                  borderRadius: "4px",
                  backgroundColor: files.length > 0 ? "#F9F9F9" : "transparent",
                  position: "relative",
                  width: "calc(70% + 40px)",
                  resize: "none",
                }}
                ref={listRef}
              >
                {files.length > 0 && (
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    {files.map((file, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontStyle: "italic",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "80%",
                          }}
                        >
                          {file.name}
                        </p>
                        <a
                          onClick={() => handleFileRemove(index)}
                          className="modal-close"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "white",
                            color: "#e84a36",
                            position: "unset",
                          }}
                          href="#"
                        >
                          <i className="fa fa-times"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Campos para verificar */}
              <a
                onClick={onClose}
                className="modal-close"
                style={{ zIndex: 1001, cursor: "pointer" }}
                href="#"
              >
                <i className="fa fa-times"></i>
              </a>
              <button
                onClick={handleSubmit}
                style={{ padding: "8px 50px 8px 30px" }}
                className="btn"
              >
                Continuar
              </button>
              {formData && <PopulateForms data={formData} />}
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "fit-content" }} ref={popupRef}>
              <div style={{ width: "100%", height: "auto", overflow: "auto" }}>
                <EditableForm
                  formData={editableFormData}
                  setFormData={setEditableFormData}
                  documento={selectedDocument}
                  loading={loading}
                  formDataFieldErrors={formDataFieldErrors}
                  setFormDataFieldErrors={setFormDataFieldErrors}
                />
              </div>
              <a
                onClick={onClose}
                className="modal-close"
                style={{ zIndex: 1001, cursor: "pointer" }}
                href="#"
              >
                <i className="fa fa-times"></i>
              </a>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Default: side by side
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <button
                  onClick={handleReturn}
                  style={{
                    padding: "8px 30px",
                    backgroundColor: "#AAA",
                    backgroundImage: "none",
                    flex: "1",
                    maxWidth: "167px",
                  }}
                  className="btn"
                >
                  Volver
                </button>
                <button
                  onClick={handleConfirm}
                  style={{
                    padding: "8px 50px 8px 30px",
                    flex: "1",
                    maxWidth: "167px",
                  }}
                  className="btn"
                >
                  Confirmar
                </button>
              </div>
              {showPopulateForms && <PopulateForms data={editableFormData} />}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FileUploaderPopup;
