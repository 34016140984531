import React, { useState, useEffect } from 'react';
import './styles/estilo.css';
import FileUploaderPopup from './components/FileUploaderPopup';
import EditableForm from './components/EditableForm';

function App() {
    const [showPopup, setShowPopup] = useState(false);

    // const handleOpenPopup = () => {
    //     setShowPopup(true);
    // };

    useEffect(() => {
        const handleShowPopup = (event) => {
            if (event.data === 'showPopup') {
                setShowPopup(true);
            }
        };

        window.addEventListener('message', handleShowPopup);

        return () => {
            window.removeEventListener('message', handleShowPopup);
        };
    }, []);

    const handleFileSubmit = (files) => {
        // Only log here if you need to do something with the files
        //console.log('Files submitted:', files);
    };

    return (
        <div>
            {/* <Toaster position="top-center" reverseOrder={false} /> */}
            {/* <h1>React App</h1> */}
            {/* <button onClick={handleOpenPopup}>Open popup</button> */}
            {showPopup && (
                <FileUploaderPopup
                    onClose={() => setShowPopup(false)}
                    onSubmit={handleFileSubmit}
                />
            )}
        </div>
    );
}

export default App;
